//新增/编辑标签
const saveLabelInfoUrl = `/gateway/blade-system/label/saveLabelInfo`;
//标签列表查询
const getLabelInfoListUrl = `/gateway/blade-system/label/getLabelInfoList`;
//标签详情查询
const getLabelInfoByIdUrl = `/gateway/blade-system/label/getLabelInfoById`;
//用户标签树查询
const getLabelTreeListUrl = `/gateway/blade-system/label/getLabelTreeList`;
//标签关联用户列表查询
const getRelatedUserListUrl = `/gateway/blade-system/label/getRelatedUserList`;

export {
  saveLabelInfoUrl,
  getLabelInfoListUrl,
  getLabelInfoByIdUrl,
  getRelatedUserListUrl,
  getLabelTreeListUrl,
};
